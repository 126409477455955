import React from 'react'
import './Footer.scss'
import { FaViber, FaFacebookMessenger } from 'react-icons/fa'
import { contact } from '../../data'
import BEMHelper from 'react-bem-helper'
import logo from '../../assets/images/logo-dark-border.png'

const Footer = () => {

  const classes = new BEMHelper('footer')

  const { phone, phoneMobile, email, url } = contact

  return (
    <footer {...classes("")}>
      <div {...classes("content-wrapper")}>
        <div>
          <h2>KONTAKT</h2>
          <p>Ugrinovačka 210</p>
          <p>11080 Beograd (Zemun)</p>
          <p>Telefon: {phoneMobile}</p>
          <p>{phone}</p>
          <p>Email:{" "}
            {/* <a href={`mailto:${email}?Subject=Pitanje%20u%20vezi%20delova`}> */}
            {email}
            {/* </a> */}
          </p>
          <p>Web:{" "}
            {/* <a href={`https://${url}`}> */}
            {url}
            {/* </a> */}
          </p>
        </div>

        <div>
          <h2>RADNO VREME</h2>
          <p>Ponedeljak - Petak:</p>
          <p>08:00 - 20:00</p>
          <p>Subota:</p>
          <p>09:00 - 15:00</p>
        </div>

        <a {...classes("logo")} href="/#"><img src={logo} alt='logo' /></a>

      </div>

      <div {...classes("copy")}>
        <p>© Copyright - 2022 | Autoteamsystem | Sva prava zadržana | Developed by:<a {...classes("me")} href="https://www.ivanmitov.com" target="blank">IvanMitov.com</a></p>
        <a {...classes("back-to-top")} href="#home">Nazad na vrh</a>
      </div>
    </footer >
  )
}

export default Footer