import React, { useState, useRef } from "react";
import './GDPR.scss'
import BEMHelper from 'react-bem-helper'
import Cookies from 'js-cookie'

const GDPR = ({ setGdprAccepted }) => {

  const classes = new BEMHelper('gdpr')
  const [readMore, setReadMore] = useState(false)

  const handleGdprAccepted = () => {
    setGdprAccepted(true)
    Cookies.set('gdprAccepted', 'gdprAccepted', { expires: 7 })
  }
  const handleReadMore = () => {
    if (readMore) {
      document.body.style.overflow = 'visible'
    } else {
      document.body.style.overflow = 'hidden'
    }
    setReadMore(!readMore)
  }

  return (
    <section {...classes('', { active: readMore })}>

      <div {...classes('more-content-wrapper', { 'read-more': readMore })}>
        <h2>Politika kolačića (eng: 'cookies')</h2>

        <p>Koristimo kolačiće kako bismo Vam omogućili što bolje korisničko iskustvo pregledanja i pretraživanja sadržaja na našem sajtu, kao i njegovo pravilno funkcionisanje.</p>

        <p>Kolačići su informacije koje neka veb-stranica koju posećuješ šalje tvom računaru, pametnom telefonu ili tabletu u svrhu poboljšavanja Vašeg korisničkog iskustva i potreba.</p>

        <p>Poštujemo Vašu privatnost i u skladu sa Zakonom o zaštiti podataka o ličnosti smo u obavezi da obezbedimo Vaš pristanak pre slanja kolačića.</p>

        <p>Ukoliko blokirate kolačiće, i dalje možete da pristupate našem sajtu i pregledate sadržaj, ali postoji mogućnost da određeni segmenti sajta neće najbolje funkcionisati. Važno je i da znate da u svakom trenutku možete promeniti postavke svog internet pretraživača i da imate mogućnost da birate da li ćete čuvati kolačiće ili ne.</p>

        <h3>Kolačići funkcionalnosti</h3>

        <p>Ovi kolačići su ključni za pravilno funkcionisanje veb-sajta. Oni nam pomažu da unapredimo Vaše korisničko iskustvo prilikom posete našem sajtu.</p>

        <p>Imate mogućnost da svoj pretraživač podesite tako da blokira ove kolačiće ili Vas upozorava na njih, ali u tom slučaju je moguće da određeni delovi sajta neće ispravno funkcionisati.</p>


        <h3>Statistički kolačići</h3>

        <p>Ovi kolačići beleže različite statističke podatke i omogućavaju nam da beležimo broj poseta veb-sajtu i analiziramo saobraćaj i promet sa ciljem merenja i unapređivanja funkcionisanja sajta. Zahvaljujući njima možemo da saznamo kako se posetioci kreću po sajtu i koje su stranice najviše posećene.</p>

        <p>Podaci koje prikupljaju ovi kolačići su anonimni. Ukoliko ne dozvolite ove kolačiće, nećemo moći da znamo kada posećujete naš sajt, niti da pratimo njegovo funkcionisanje.</p>


        <h3>Marketing kolačići</h3>

        <p>Mogu biti postavljeni na naš veb-sajt od strane partnerskih sajtova za oglašavanje. Oni se koriste kako bi se kreirao profil Vaših interesovanja i navika, a sve u cilju prikazivanja adekvatnih reklama na drugim sajtovima.</p>


        <h3>Kako se mogu obrisati kolačići?</h3>

        <p>Sve postavke kolačića i informacije o istim možete pronaći u podešavanjima Vašeg internet pretraživača. Više o brisanju kolačića možete pročitati <a {...classes('link')} href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blanc">ovde.</a></p>
      </div>
      <div {...classes('cta-wrapper')}>
        <p>Ova veb-stranica koristi kolačiće (eng: 'cookies') kako bismo Vam pružili što bolje korisničko iskustvo. Prihvatanjem korišćenja kolačića slažete se sa politikom privatnosti.</p>
        <button {...classes('cta')} onClick={handleGdprAccepted}>Prihvati kolačiće</button>
        <button {...classes('cta')} onClick={handleReadMore}>{readMore ? 'Umanji' : 'Pročitaj više'}</button>
      </div>
    </section >
  )
}

export default GDPR