import slide1 from './assets/images/5.jpg'
import slide2 from './assets/images/1.jpg'
import slide3 from './assets/images/3.jpg'
import slide4 from './assets/images/11.jpg'
import slide1Tablet from './assets/images/5Tablet.jpg'
import slide2Tablet from './assets/images/1Tablet.jpg'
import slide3Tablet from './assets/images/3Tablet.jpg'
import slide4Tablet from './assets/images/11Tablet.jpg'
import ulja from './assets/images/ulja.jpg'
import kozmetika from './assets/images/kozmetika.jpg'
import filteri from './assets/images/filteri.jpg'
import akumulatori from './assets/images/akumulatori.jpg'
import elektrika from './assets/images/elektrika.jpg'
import delovi from './assets/images/delovi.jpg'

export const contact = {
  url: 'teamsystem.rs',
  phoneMobile: '064 119 43 84',
  phone: '011 716 97 57',
  email: 'teamsystemzemun@gmail.com',
  viber: '381641194384',
  nav: [
    {
      anchor: '#home',
      label: 'POČETNA'
    },
    {
      anchor: '#parts',
      label: 'DELOVI'
    },
    {
      anchor: '#contact',
      label: 'KONTAKT'
    },
    {
      anchor: '#about-us',
      label: 'O NAMA'
    },
  ]
}

export const slider = {
  slides: [
    {
      label: 'Auto Delovi',
      desc: 'Asortiman je baziran na prodaji delova za vozila: Audi, Volkswagen, Opel, Fiat i Zastava.',
      img: slide1,
      imgTablet: slide1Tablet,
      ctaLabel: 'Istrazi'
    },
    {
      label: 'Auto Elektrika',
      desc: 'Velika ponuda akumulatora, sijalica, auto remenja.',
      img: slide2,
      imgTablet: slide2Tablet,
      ctaLabel: 'Istrazi'
    },
    {
      label: 'Motorna ulja i filteri',
      desc: 'Širok sortiman motornih ulja mineralnog i sintetičkog sastava.',
      img: slide3,
      imgTablet: slide3Tablet,
      ctaLabel: 'Istrazi'
    },
    {
      label: 'Auto Kozmetika',
      desc: 'Širok asortiman osveživača kabine, tečnosti za šoferšajbnu, sredstva za poliranje karoserije.',
      img: slide4,
      imgTablet: slide4Tablet,
      ctaLabel: 'Istrazi'
    }
  ]
}

export const aboutUs = {
  title: 'O Nama'
}

export const parts = {
  types: ['Audi', 'Volkswagen', 'Opel', 'Fiat', 'Zastava'],
  subtypes: [
    {
      label: 'Auto delovi',
      imgSrc: delovi
    },
    {
      label: 'Auto elektrika',
      imgSrc: elektrika
    },
    {
      label: 'Akumulatori',
      imgSrc: akumulatori
    },
    {
      label: 'Motorna ulja',
      imgSrc: ulja
    },
    {
      label: 'Filteri',
      imgSrc: filteri
    },
    {
      label: 'Auto kozmetika',
      imgSrc: kozmetika
    }
  ]
}