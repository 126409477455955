import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper'
import './Hero.scss'
import BEMHelper from 'react-bem-helper'
import { slider } from '../../data'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CTA from "../CTA/CTA";

const Hero = () => {

  const classes = new BEMHelper('hero')

  const navigationPrev = useRef(null)
  const navigationNext = useRef(null)

  return (
    <section id="home" {...classes('')}>
      <Swiper
        {...classes('slider')}
        modules={[Navigation, Pagination, Autoplay]}
        // spaceBetween={50}
        slidesPerView={1}
        grabCursor={true}
        navigation={{
          prevEl: navigationPrev.current,
          nextEl: navigationNext.current
        }}
        style={{
          "--swiper-pagination-color": "#0e1f4a",
        }}
        pagination={{
          // dynamicBullets: true,
          // type: "progressbar",
          clickable: true,
        }}
        autoplay={{
          delay: 6500,
          disableOnInteraction: false,
        }}
        loop={true}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrev.current;
          swiper.params.navigation.nextEl = navigationNext.current;
        }}
        onSlideChange={() => console.log()}
        onSwiper={() => console.log()}
      >
        {
          slider.slides.map((slide, index) => {
            return (
              <SwiperSlide {...classes('slide')} key={index}>
                {({ isActive }) => (
                  <>
                    <div {...classes('slide-text-wrapper', { active: isActive })}>
                      <h2 {...classes('slide-title')}>{slide.label}</h2>
                      <p {...classes('slide-desc')}>{slide.desc}</p>
                      <CTA label={slide.ctaLabel} src='#parts' />
                    </div>
                    <picture {...classes('slide-img')}>
                      <source media="(max-width:1024px)" srcSet={slide.imgTablet} />
                      <img  {...classes('slide-img')} src={slide.img} alt="slide1" />
                    </picture>
                  </>
                )}
              </SwiperSlide>
            )
          })
        }

        <button ref={navigationPrev} {...classes('navigation-button', 'prev')}>❮</button>
        <button ref={navigationNext} {...classes('navigation-button', 'next')}>❯</button>
      </Swiper>
    </section >
  )
}

export default Hero