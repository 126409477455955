import React, { useState } from "react";
import './App.scss';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Masthead from './components/Masthead/Masthead'
import AboutUs from './components/AboutUs/AboutUs'
import Delovi from './components/Delovi/Delovi';
import GDPR from './components/GDPR/GDPR';
import Cookies from 'js-cookie';

const isGdprAccepted = Cookies.get('gdprAccepted')

function App() {
  const [gdprAccepted, setGdprAccepted] = useState(isGdprAccepted ? true : false)
  return (
    <div className="App">
      <Masthead />
      <Hero />
      <Delovi />
      <Contact />
      <AboutUs />
      <Footer />
      {!(isGdprAccepted === 'gdprAccepted') && !gdprAccepted && <GDPR setGdprAccepted={setGdprAccepted} />}
    </div>
  );
}

export default App;