import React, { useState, useEffect } from 'react'
import { AiFillFacebook, AiFillTwitterCircle, AiFillLinkedin } from 'react-icons/ai'
import { BsEnvelope, BsEnvelopeFill } from 'react-icons/bs'
import { BiMobileVibration, } from 'react-icons/bi'
import { FiPhoneCall } from 'react-icons/fi'
import { FaViber } from 'react-icons/fa'
import { contact } from '../../data'
import BEMHelper from 'react-bem-helper'
import logo from '../../assets/images/logo-dark-border-fat.png'
import logoSmall from '../../assets/images/logo-dark.png'
import './Masthead.scss'

const Masthead = () => {

  const classes = new BEMHelper('masthead')

  const { phone, phoneMobile, email, nav, viber } = contact

  const [scrolledTop, setScrolledTop] = useState(window.scrollY)
  const [showMenu, setShowMenu] = useState(window.innerWidth > 576)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrolledTop(window.scrollY)
    })
    window.addEventListener('resize', () => {
      if (window.innerWidth > 576) {
        setShowMenu(true)
      } else {
        setShowMenu(false)
      }
    })
  }, [])

  return (
    <section {...classes('', { scrolled: scrolledTop > 50 })}>
      <div {...classes('top-wrapper')}>
        <div {...classes('top', '', 'column-control')}>
          <a {...classes('contact-item', 'phone-mobile')} href={`tel:${phoneMobile}`}><BiMobileVibration />&nbsp;{phoneMobile}</a>
          <a {...classes('contact-item', 'phone')} href={`tel:${phone}`}><FiPhoneCall />&nbsp;{phone}</a>
          <a {...classes('contact-item', 'email')} href={`mailto:${email}?Subject=Upit`}>
            <BsEnvelope />
            <span {...classes('email-text')}>&nbsp;{email}</span>
          </a>
          <a href={`viber://chat/?number=%2B${viber}`} {...classes('social-item')}><FaViber /></a>

          <div {...classes('social')} >
            {/* <a href="https://google.com" {...classes('social-item')}><FaViber /></a> */}
          </div>
        </div>
      </div>

      <div {...classes('header-wrapper')}>
        <header {...classes('header', '', 'column-control')}>
          <div {...classes('hamburger')} onClick={() => setShowMenu(!showMenu)}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <a {...classes('logo-wrapper')} href="/#"><img src={!showMenu ? logoSmall : (scrolledTop > 50 ? logoSmall : logo)} {...classes('logo')} alt='logo' /></a>

          {
            showMenu && (
              <nav {...classes('nav')}>
                <ul {...classes('nav-items')}>
                  {
                    nav.map((item, index) => <li key={index}><a {...classes('nav-link')} href={item.anchor} >{item.label}</a></li>)
                  }
                </ul>
              </nav>
            )
          }
        </header>
      </div>
    </section >
  )
}

export default Masthead