import React, { useState, useRef } from "react";
import { FaViber } from 'react-icons/fa'
import "./Contact.scss";
import { contact } from "../../data";
import BEMHelper from "react-bem-helper";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const API_PATH = 'https://teamsystem.rs/PHPMail/teamsystemmail.php';
const myVerifyVar = 'DESET';

const Contact = () => {
  const classes = new BEMHelper("contact");

  const reRef = useRef()

  const { phone, phoneMobile, email, url, viber } = contact;

  const [subjectEl, setSubjectEl] = useState('')
  const [messageEl, setMessageEl] = useState('')
  const [emailEl, setEmailEl] = useState('')
  const [nameEl, setNameEl] = useState('')
  const [myVerify, setMyVerify] = useState('')
  const [verifyError, setVerifyError] = useState(false)
  const [sentModal, setSentModal] = useState(false)

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setVerifyError(false)
    const myVerifyUppercase = myVerify.toUpperCase()

    const reToken = await reRef.current.executeAsync()
    reRef.current.reset()

    if (myVerifyUppercase === myVerifyVar) {
      setVerifyError(false)
      try {
        const response = await axios.post(API_PATH, {
          subject: subjectEl,
          message: messageEl,
          email: emailEl,
          name: nameEl,
          myVerify: myVerifyUppercase,
          reToken: reToken
        })
        setSentModal(true)
        setSubjectEl('');
        setMessageEl('');
        setEmailEl('');
        setNameEl('');
        setMyVerify('');

      } catch (error) {
        console.log(error.response)
      }
    } else {
      setVerifyError(true)
    }

    /*
    // axios.post(API_PATH, {
    //   subject: subjectEl,
    //   message: messageEl,
    //   email: emailEl,
    //   name: nameEl
    // })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // axios({
    //   method: 'post',
    //   url: `${API_PATH}`,
    //   headers: { 'content-type': 'application/json' },
    //   data: data
    // })
    //   .then(result => {
    //     console.log(result.data.sent)
    //   })
    //   .catch(error => this.setState({ error: error.message }));
    */
  }

  return (
    <>
      <section id="contact" {...classes("", "", "smooth-scroll")}>

        <div {...classes("title-wrapper")}>
          <h3 {...classes("title")}>KONTAKT</h3>
        </div>

        <div {...classes("map")}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5657.601495102904!2d20.40057!3d44.845991!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1e842180b9fe20c!2sTEAM+SYSTEM!5e0!3m2!1ssr!2srs!4v1541236867216"
            width="100%"
            height="600"
            frameBorder="0"
            title="mapa"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </div>

        <div {...classes("content-wrapper")}>
          <div {...classes("form-wrapper")}>
            <h3 {...classes("form-title")}>Pošaljite nam Email</h3>

            <form
              // action={`https://teamsystem.rs/phpmailer-main/index.php?subject=${subjectEl}&body=${messageEl}`}
              onSubmit={handleFormSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <div {...classes("form-items-wrapper")}>
                <div {...classes("form-item")}>
                  <label {...classes("form-label")}>Ime*</label>
                  <input id="form-name-input" type="text" required value={nameEl} onChange={(e) => setNameEl(e.target.value)} />
                </div>

                <div {...classes("form-item")}>
                  <label {...classes("form-label")}>Email*</label>
                  <input id="form-mail-input" type="email" required value={emailEl} onChange={(e) => setEmailEl(e.target.value)} />
                </div>
              </div>

              <div {...classes("form-item")}>
                <label {...classes("form-label")}>Naslov*</label>
                <input id="form-subject-input" type="text" required value={subjectEl} onChange={(e) => setSubjectEl(e.target.value)} />
              </div>

              <div {...classes("form-item")}>
                <label {...classes("form-label")}>Poruka*</label>
                <textarea
                  id="form-message-input"
                  rows="14"
                  required
                  value={messageEl}
                  onChange={(e) => setMessageEl(e.target.value)}
                ></textarea>
              </div>

              <div {...classes("form-item", { 'verify-error': verifyError })}>
                <label {...classes("form-label")}>Rečima ukucajte broj '10' kako bismo potvrdili da niste bot*</label>
                <input id="form-subject-input" type="text" required value={myVerify} onChange={(e) => setMyVerify(e.target.value)} />
                <div {...classes("error-message")}>Pogrešno ste ukucali traženi broj, molimo pokušajte ponovo.</div>
              </div>
              <div>
                <input
                  {...classes("form-submit-button")}
                  type="submit"
                  value="Pošalji poruku"
                ></input>
                <ReCAPTCHA
                  sitekey='6LdtkfchAAAAAB8CPy969-l6OrsGxnY79_sFxFoN'
                  size='invisible'
                  ref={reRef}
                />
              </div>
            </form>
          </div>

          <div {...classes("aside")}>
            <div {...classes("aside-item")}>
              <h3 {...classes("aside-title")}>RADNO VREME:</h3>
              <p>Ponedeljak - Petak:</p>
              <p>08:00 - 20:00</p>
              <p>Subota:</p>
              <p>09:00 - 15:00</p>
            </div>
            <div {...classes("aside-item")}>
              <h3 {...classes("aside-title")}>KONTAKT:</h3>
              <p>Ugrinovačka 210</p>
              <p>11080 Beograd (Zemun)</p>
              <p>Telefon: {phoneMobile}</p>
              <p>{phone}</p>
              <p>
                Email:{" "}
                {/* <a href={`mailto:${email}?Subject=Pitanje%20u%20vezi%20delova`}> */}
                {email}
                {/* </a> */}
              </p>
              <p>
                Web:{" "}
                {/* <a href={`https://${url}`}> */}
                {url}
                {/* </a> */}
              </p>
              <a href={`viber://chat/?number=%2B${viber}`} {...classes('aside-social-item')}><FaViber /></a>
            </div>
          </div>
        </div>
      </section>
      {
        sentModal && <section {...classes('message-sent')}>
          <h3>Hvala na poruci, odgovorićemo u najkraćem roku!</h3>
          <button onClick={() => setSentModal(false)}>Ugasi</button>
        </section>
      }
    </>
  );
};

export default Contact
