import React, { useRef } from 'react'
import BEMHelper from 'react-bem-helper'
import './Delovi.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper'
import { parts } from '../../data'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Delovi = () => {
  const classes = new BEMHelper('parts')

  const navigationPrev = useRef(null)
  const navigationNext = useRef(null)

  return (
    <section id="parts" {...classes("", "", "smooth-scroll")}>
      <div {...classes("title-wrapper")}>
        <h3 {...classes("title")}>DELOVI</h3>
      </div>

      <div {...classes('content-wrapper')}>
        <div {...classes('content')}>
          <h2  {...classes('types-title')}>U našoj ponudi naći ćete delove za sve vrste automobila, kao što su:</h2>
          <div {...classes('types')}>
            {
              parts.types.map((type, i) => <h2 key={i} {...classes('type')}>{type}</h2>)
            }
          </div>
          {/* <h2  {...classes('subtypes-title')}>Nasi delovi su:</h2> */}
          <div {...classes('subtypes')}>
            {
              parts.subtypes.map((subtype, i) => <h3 key={i} {...classes('subtype')}>{subtype.label}</h3>)
            }
          </div>
        </div>
        <div {...classes('slider')}>
          <Swiper
            {...classes('slider')}
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={'auto'}
            grabCursor={true}
            loop={true}
            breakpoints={{
              // when window width is >= 640px
              576: {
                // slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3.6,
              }
            }}
            navigation={{
              prevEl: navigationPrev.current,
              nextEl: navigationNext.current
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrev.current;
              swiper.params.navigation.nextEl = navigationNext.current;
            }}
            style={{
              "--swiper-pagination-color": "#0e1f4a",
            }}
          >
            {
              parts.subtypes.map((subtype, index) => {
                return (
                  <SwiperSlide {...classes('slide')} key={index}>
                    {() => (
                      <>
                        <div {...classes('slide-text-wrapper')}>
                          <h2 {...classes('slide-title')}>{subtype.label}</h2>
                        </div>
                        {/* <div>
                          <p {...classes('slide-desc')}>Neki random tekst koji se odnosi na ovo gore sto pise.</p>
                        </div> */}
                        <img  {...classes('slide-img')} src={subtype.imgSrc} alt="slide1" />
                      </>
                    )}
                  </SwiperSlide>
                )
              })
            }

            <button ref={navigationPrev} {...classes('navigation-button', 'prev')}>❮</button>
            <button ref={navigationNext} {...classes('navigation-button', 'next')}>❯</button>
          </Swiper>
        </div >

      </div>
    </section >
  )

}

export default Delovi