import React, { useState, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import './AboutUs.scss'
import onama from '../../assets/images/onama.jpg'

const AboutUs = () => {

  const classes = new BEMHelper('about-us')

  return (
    <section id="about-us" {...classes("")}>
      <div {...classes("title-wrapper")}>
        <h3 {...classes("title")}>O NAMA</h3>
      </div>
      <div {...classes("content-wrapper")}>
        <img {...classes("image")} src={onama} alt="prodavnica" />
        <ul {...classes("content")}>
          <li>2002. godine počela je sa radom prodavnica auto delova <span>'TeamSystem'</span> sa namerom da kupcima ponudi izbor kvalitetnih auto delova.</li>
          <li>U početku se asortiman bazirao na prodaji delova za vozila <span>Volkswagen</span>, <span>Audi</span>, <span>Zastava</span>, <span>Lada</span>,  i <span>Opel</span>, a kasnije je asortiman proširen na delove za <span>Fiat</span> vozila.</li>
          <li>Poslovna politika zasnovana je na konstantnoj ponudi kvalitetnih auto delova i prateće opreme, a sve u cilju zadovoljavanju želja i potreba potrošača.</li>
          <li>Dve decenije uspešnog rada samo jedan je od razloga da nam poverite Vaše poverenje.</li>
          <li>Osim auto delova, u ponudi je širok asortiman auto elektrike, auto kozmetike, akumulatora, motornih ulja, auto remenja, filteri ulja, vazduha, goriva, kabine itd.</li>
        </ul>
      </div>
    </section >
  )
}

export default AboutUs